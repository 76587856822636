import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from 'contexts/AuthContext';
import NavigationContainer from 'containers/NavigationContainer';
import LogRocket from 'logrocket';
import './App.css';
LogRocket.init('nqa9k1/wmx');
const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <NavigationContainer />
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
