import { User } from 'contexts/AuthContext';
import jwtDecode from 'jwt-decode';
import { clearDefunctToken, saveToken, saveTokenExpiry } from 'utils/authToken';
const audigentAudBankId = 31;

export const refreshAuthTokenHelper = async (data) => {
  const claims = jwtDecode<User>(data.access);
  clearDefunctToken();
  saveToken(data.access);
  saveTokenExpiry(claims.exp);
  return { data, claims };
};

export const currentUserHelper = async ({ destinations, email, ...user }) => ({
  ...user,
  destinations: user && destinations.filter((d) => d.id !== audigentAudBankId),
  email,
});
