import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { NavItem } from './components/NavItem';
import routes from 'containers/NavigationContainer/routes';
import PopOver, { PopOverItem } from 'components/PopOver';
import { Avatar } from 'components/Avatar';
import { Logo } from 'components/Logo';
import './style.css';
import { ChangePasswordModal } from './components/ChangePassword';
import LogRocket from 'logrocket';

const AppContainer = ({ children }) => {
  const [isUserMenuVisible, setIsUserMenuVisible] = React.useState(false);
  const [changePassword, setChangePassword] = React.useState(false);
  const [headerScrolled, setHeaderScrolled] = React.useState(false);
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const {
    signOut,
    state: { user },
  } = useAuth();

  const handleLogout = (e) => {
    e.preventDefault();
    signOut();
  };

  const handleChangePasswordModal = () => {
    // e.preventDefault();
    setChangePassword(true);
  };

  const listenScrollEvent = () => {
    if (window.scrollY < 43) {
      return setHeaderScrolled(false);
    } else if (window.scrollY > 40) {
      return setHeaderScrolled(true);
    }
  };

  React.useEffect(() => {
    if (user) {
      LogRocket.identify(user.email);
    }
  }, [user]);

  React.useEffect(() => {
    window.addEventListener('scroll', () => window.requestAnimationFrame(listenScrollEvent));

    return () => window.removeEventListener('scroll', () => window.requestAnimationFrame(listenScrollEvent));
  }, []);

  return (
    <>
      <div className="app-container column">
        <div data-html2canvas-ignore="true" className={`app-header-container ${headerScrolled ? 'scrolled' : ''}`}>
          <div className={'app-header'}>
            <div className="row center">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div data-html2canvas-ignore="true" className="app-navigation row center grow gap-m">
              {routes
                .filter((d) => d.navbar)
                .map((d) => (
                  <NavItem key={d.slug} label={d.label} path={d.path} />
                ))}
            </div>
            <div style={{ position: 'relative' }}>
              <Avatar name={user.email} onClick={() => setIsUserMenuVisible(true)} />
              <PopOver isVisible={isUserMenuVisible} onCancel={() => setIsUserMenuVisible(false)}>
                <PopOverItem>
                  <div className="secondary">{user.email}</div>
                </PopOverItem>
                <PopOverItem onClick={handleChangePasswordModal}>Change Password</PopOverItem>
                <PopOverItem>
                  <a href="https://audigent.atlassian.net/servicedesk/customer/portal/1" target="_blank">
                    Jira Service Management
                  </a>
                </PopOverItem>
                <PopOverItem>
                  <a onClick={handleLogout} href={`#logout`}>
                    Logout
                  </a>
                </PopOverItem>
              </PopOver>
            </div>
          </div>
        </div>
        {children}
      </div>
      <ChangePasswordModal visible={changePassword} onClose={() => setChangePassword(false)} />
    </>
  );
};

export default AppContainer;
